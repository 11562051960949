import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Tabs, { TabHeader, NavItem, TabContent, TabPane } from "@ui/tabs/layout-one";
import { SectionWrap } from "./style";

const TabsSection = () => {
	return (
		<SectionWrap>
			<Container>
				<Row>
					<Col lg={12}>
						<Heading mb="40px" textalign="center">AI in Industry</Heading>
					</Col>
				</Row>
				<Row>
					<Col lg={12}>
						<Tabs defaultActiveKey="our-mission-tab">
							<TabHeader>
								<NavItem eventKey="our-mission-tab">Tourism & Hospitality</NavItem>
								<NavItem eventKey="our-services-tab">Smart Farm</NavItem>
								<NavItem eventKey="partners-tab">Oil/Gas Exploration</NavItem>
								<NavItem eventKey="our-awards-tab">Our Awards</NavItem>
							</TabHeader>
							<TabContent pt="50px">
								<TabPane eventKey="our-mission-tab">
									<Heading as="h4" mb="20px">Partner with HKPU and Macau University</Heading>
									<p>Tourism management is the oversight of all activities related to the tourism and hospitality industries. It's a multidisciplinary field that prepares people with the interest, experience, and training for management positions in the food, accommodations, and tourism industry.</p>
									<p>TULIP Lab has nearly two decades' consultation experience in tourism and hospitality industry. We are specialized in group behavior pattern analysis, tourism demand forecasting, and competitiveness discovery. We are also capable of data-supported commercial service and consultation related to tourism/hospitality industry.</p>
								</TabPane>
								<TabPane eventKey="our-services-tab">
									<Heading as="h4" mb="20px">Partner with Chonnam National University</Heading>
									<p>Smart Farming has emerged as an application of the Internet of Things (IoT) with the development of information and communication technology. Smart farming usually refers to the utilization of digital technologies, IoT, cloud computing, robotics, sensors, location systems and artificial intelligence on the farm. It is also known as digital farming, data-driven farming, Agriculture 4.0 and sometimes Agriculture 5.0. The infrastructure of the farm consists of sensors for environmental data and surveillance cameras. Through the gateways, those data are connected to the service platforms accessible to farmers. Similar to other agriculture technologies, smart farming aims to control and improve food manufacture.</p>
									<p>TULIP Lab hold one international patent in smart farming, and we have been developing method to collect smart farming data as such data provide valuable information such as an optimum condition for plant growth, yield prediction, pests and disease detection. </p>
								</TabPane>
								<TabPane eventKey="partners-tab">
								<Heading as="h4" mb="20px">Partner with Dasheng Petroleum Engineering Technology Service</Heading>
									<p>The surveillance of oil and gas wells often faces complex environments such as heavy fog and rain, sandstorms, which result in a lot of noises in the monitoring images. </p>
									<p>with the support of big data, the remote monitoring of oil and gas wells based on intelligence methods can not only solve the problems caused by manual monitoring, but also increase the management of oil and gas wells to the extent of digitization and intelligence. Thus, it will achieve the goal of reducing the labor intensity of workers and energy consumption, achieving safe production, improving the information level of oil and gas production and management, and promoting efficient development of oil and gas wells.</p>
								</TabPane>
								<TabPane eventKey="our-awards-tab">
									<Heading as="h4" mb="20px">International Patent</Heading>
									<p>Patent: IOT Architecture For Manufacture of Similar Products, Application No: 201941041825A, 25/10/2019, India</p>
									<Heading as="h4" mb="20px">Industry Awards</Heading>
									<p>IFITT Journal Paper of the Year Award (2018)</p>
									<p>IFITT Journal Paper of the Year Award (2016)</p>
								</TabPane>
							</TabContent>
						</Tabs>
					</Col>
				</Row>
			</Container>
		</SectionWrap>
	)
}

export default TabsSection
